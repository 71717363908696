<template>
  <div>
    <main v-if="Disease && Object.keys(Disease).length">
      <PagesSlider
        v-if="Disease.banners && Disease.banners.length"
        :title="Disease.title"
        :banners="Disease.banners"
        :color="Disease.color"
      />
      <Breadcrumbs
        v-if="breadcrumbsList"
        :color="Disease.color"
        :pages="
          breadcrumbsList
        "
      />
      <section class="therapy-areas failure-page__section">
        <div class="content">
          <div class="failure-page__row">
            <div class="failure-page__categories">
              <div
                class="materials"
                v-if="filteredLinks && filteredLinks.length"
              >
                <FileBlock
                  v-for="material in filteredLinks"
                  :key="material.id"
                  :material="material"
                  @linkClick="linkClick"
                />
              </div>
              <div
                class="failure-page__title"
                v-if="Disease.name"
                v-html="Disease.name"
              ></div>
              <div
                ref="description"
                class="failure-page__description"
                v-html="Disease.description"
              ></div>
              <div class="failure-page__categories-row">
                <InfoDrop
                  v-for="(info, ind) in filteredContent"
                  :key="ind"
                  :color="Disease.color"
                  :item="info"
                />
              </div>
            </div>
            <RightAside
              @medicationsClick="medicationsClick"
              @calendarClick="calendarClick"
              @libClick="libClick"
              v-if="asideItems && asideItems.length && filteredContent.length"
              :items="asideItems"
              class="failure-page__aside"
            />
          </div>
          <LibrarySlider
            v-if="
              Disease.library && Disease.library.length && sliderItems.length
            "
            :nosology="{ title: Disease.title }"
            :items="sliderItems"
            class="mt-16 mb-16 pb-0 pt-0 mt-0"
            style="background-color: #fff"
            :color="Disease.color"
            :class="{ 'mt-16': filteredContent.length }"
          />
          <RightAside
            @medicationsClick="medicationsClick"
            @calendarClick="calendarClick"
            @libClick="libClick"
            v-if="asideItems && asideItems.length && !filteredContent.length"
            :items="asideItems"
            class="failure-page__aside medication-aside_flexible"
          />
        </div>
      </section>
    </main>
    <div v-else-if="Disease === 404">
      <Choch class="inPage" />
    </div>
    <div v-else-if="Disease === false"><Error class="inPage" /></div>
  </div>
</template>

<script>
import AsideBlock from "@/components/pageComponents/AsideBlock.vue";
import Breadcrumbs from "@/components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "@/components/pageComponents/InfoDrop.vue";
import FileBlock from "@/components/pageComponents/FileBlock.vue";
import PagesSlider from "@/components/main/PagesSlider.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import { mapActions, mapGetters } from "vuex";
import RightAside from "../components/pageComponents/RightAside.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";

export default {
  metaInfo() {
    if (this.Disease.title) {
      return { title: this.Disease.title.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.Disease.title };
    }
  },
  components: {
    Breadcrumbs,
    AsideBlock,
    InfoDrop,
    PagesSlider,
    FileBlock,
    Choch,
    Error,
    RightAside,
    LibrarySlider,
  },
  name: "Disease",
  data: (vm) => ({
    items: [],
    index: null,
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 1919,
      desktop_l: 2559,
      desktop_xl: 3839,
      desktop_xxl: 9999999,
    },
  }),
  computed: {
    ...mapGetters(["Disease"]),
    breadcrumbsList() {
      let obj = [
        {
            name: 'Терапевтические области',
            link: {
              name: 'Nosology',
            },
          },
          {
            name: this.Disease.parent.title,
            link: {
              name: 'NosologyDetail',
              params: { slug: this.Disease.parent.slug },
            },
          },
      ]
      if(this.Disease && this.Disease.subgroup) {
        obj.push({
            name: this.Disease.subgroup.title,
            link: {
              name: 'Disease',
              params: { slug: this.Disease.parent.slug, disease: this.Disease.subgroup.slug },
            },
          })
      }
      obj.push({ name: this.Disease.title })
      return obj
    },
    asideItems() {
      if (this.Disease && Object.keys(this.Disease).length) {
        return [
          {
            title: "Препараты АЗ",
            // descr: this.Disease.parent.title,
            image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-1_small.jpg`,
            image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-1.jpg`,
            theme: "white",
            event: "medicationsClick",
            to: {
              name: "Medications",
              query: {
                therapeutic_areas: this.Disease.parent.slug,
                disease: this.Disease.slug,
              },
            },
          },
          {
            title: "Мероприятия",
            image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-2.jpg`,
            image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-2_small.jpg`,
            links: [
              {
                event: "calendarClick",
                title: "Предстоящие",
                to: {
                  name: "Events",
                  query: {
                    therapeutic_areas: this.Disease.parent.slug,
                  },
                },
              },
              {
                event: "calendarClick",
                title: "Архив",
                to: {
                  name: "ArchiveEvents",
                  query: {
                    therapeutic_areas: this.Disease.parent.slug,
                    disease: this.Disease.slug,
                  },
                },
              },
            ],
          },
          {
            title: "Библиотека",
            image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-3.jpg`,
            image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-3_small.jpg`,
            links: [
              {
                event: "libClick",
                title: "Публикации",
                to: {
                  name: "TextMaterials",
                  query: {
                    therapeutic_areas: this.Disease.parent.slug,
                    disease: this.Disease.slug,
                  },
                },
              },
              {
                event: "libClick",
                title: "Видеоматериалы",
                to: {
                  name: "VideoMaterials",
                  query: {
                    therapeutic_areas: this.Disease.parent.slug,
                    disease: this.Disease.slug,
                  },
                },
              },
              {
                event: "libClick",
                title: "Подкасты",
                to: {
                  name: "Podcasts",
                  query: {
                    therapeutic_areas: this.Disease.parent.slug,
                    disease: this.Disease.slug,
                  },
                },
              },
            ],
            theme: "dark",
          },
        ];
      } else {
        return [];
      }
    },
    filteredContent() {
      return this.Disease.content.filter((el) => el.active);
    },
    filteredLinks() {
      return this.Disease.links.filter((el) => el.active);
    },
    formattedImageList() {
      let imageList = [];
      let usedImages = Object.keys(this.Disease)
        .filter((el) => el.includes("banner_"))
        .map((el) => el.replace("banner_", ""));
      for (let imgFormat of usedImages) {
        if (
          this.Disease["banner_" + imgFormat] &&
          Object.keys(this.media).includes(imgFormat)
        ) {
          imageList.push({
            src:
              this.$root.host +
              this.Disease["banner_" + imgFormat].replace("http:", "https:"),
            media: this.media[imgFormat],
          });
        }
      }
      imageList.sort((a, b) => (a.media > b.media ? 1 : -1));
      return imageList;
    },
    sliderItems() {
      return this.Disease.library.length
        ? this.Disease.library.map(function (el) {
            return {
              ...el,
              page: el.video
                ? "VideoDetail"
                : el.episodes
                ? "PodcastDetail"
                : "TextDetail",
            };
          })
        : null;
    },
  },
  methods: {
    ...mapActions(["fetchDisease"]),
    medicationsClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "disease page medications click", {
          "disease page medications click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    calendarClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "disease page events click", {
          "disease page events click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    libClick(item, text) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "disease page library materails click", {
          "disease page library materails click": {
            "block name": text,
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    linkClick() {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "disease page patient materials click", {
          "disease page patient materials click": {
            ...this.$root.ymFields,
          },
        });
      }
    },
  },
  mounted() {
    const vm = this;
  
  },
  watch: {
    "$route.params.disease": {
      handler: async function (disease) {
        if (!this.$route.query.access) {
          await this.fetchDisease({
            disease: this.$route.params.disease,
            slug: this.$route.params.slug,
            access: this.$route.query.access || null
          });
        }
        if (this.Disease.link && this.$route.path !== this.Disease.link) {
          this.$router
            .replace({
              query: { ...this.$route.query },
              params: { ...this.$route.params },
              path: this.Disease.link,
            })
            .catch(() => {});
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.materials {
  display: grid;

  margin-bottom: 40px;

  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.failure-page {
  &__categories {
    max-width: 800px;
  }
  &__row {
    display: grid;

    column-gap: 136px;
    grid-template-columns: 2fr 280px;
    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
    .failure-page__aside {
      @media screen and (max-width: 1220px) {
        display: flex;
        overflow-x: auto;
        align-items: center;
        justify-content: flex-start;

        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
      }
    }
  }
  &__section {
    padding-top: 64px;
  }
  &__title {
    margin-bottom: 40px;
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 42px;
    line-height: 48px;
    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
  // &__title_big {
  //   margin-bottom: 40px;
  //   color: #1f1f1f;
  //   font-family: "Roboto Slab", sans-serif;
  //   font-weight: 400;
  //   font-style: normal;
  //   font-size: 42px;
  //   line-height: 48px;
  //   @media screen and (max-width: 767px) {
  //     font-size: 32px;
  //     line-height: 38px;
  //   }
  // }
  &__description {
    color: #656969;

    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
  }
}
</style>
